import { socketEpic, statisticsReducer, } from './store';

const noComponent = () => Promise.resolve({ default: () => null, });

export default () => ({
  components: {
    GameHeader: noComponent,
    GameFooter: noComponent,
    GameBoard: () => import('./views/GameBoard'),
    GameLeftSideDrawer: () => import('./views/GameLeftSideDrawer/'),
    GameRightSideDrawer: () => import('./views/GameRightSideDrawer/'),
    ResultMessage: () => import('./components/ResultMessage/ResultMessage'),
    LeftSideDrawerContent: noComponent,
    RightSideDrawerContent: noComponent,
    VideoOverlay: () => import('./views/VideoOverlay'),
    CallBetsToolbar: noComponent,
    BetToolbar: noComponent,
    ChipSelector: noComponent,
    GameTools: noComponent,
    ProgressBar: noComponent,
  },
  store: {
    epics: [ socketEpic, ],
    reducers: {
      statistics: statisticsReducer,
    },
  },
  props: {
    GameLeftSideDrawer: { style: { top: 'calc(100% * -0.10)', }, },
    GameRightSideDrawer: { style: { top: 'calc(100% * -0.10)', }, },
  },
  retailMode: true,
  // gameBoardHeight: 100,
  // desktopBoardToggle: true,
  // landscapeToolbarOrientation: "vertical",
  // hideLeftSideDrawer: true
});
