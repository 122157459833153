import { of, } from 'rxjs';
import { LOADING, } from '@ezugi/constants';
import { mapPropNames, } from '@ezugi/utils';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import humps from 'humps';

import socketActions from '../../actions/socket';
import betActions from '../../actions/bets';
import statisticsActions from '../../actions/statistics';

const { socket, } = socketActions;
const { bet, history, } = betActions;
const { statistics, } = statisticsActions;

const {
  videoActions: { video, },
  roundActions: { round, },
  gameActions: { game, },
  settingsActions: { settings, },
  uiActions: { progressBar, },
} = bootstrapActions;

export default (socketMessage) => {
  const { VideoStreamData, roundId, timeStamp, RoundTripStartTime, data, } = socketMessage;
  /* eslint-disable no-shadow */
  const { Group, History, ColdNumbers, HotNumbers, Statistics, playerBetOfThisRound, } = data;

  return of(
    socket.success(),
    settings.init(),
    video.set({ streamData: VideoStreamData, }),
    round.set({
      roundStatus: LOADING,
      roundId,
      roundTime: RoundTripStartTime,
      timestamp: timeStamp,
    }),
    game.set({
      gameResults: History,
      coldNumbers: ColdNumbers,
      hotNumbers: HotNumbers,
    }),
    statistics.set({
      gameResults: History,
      group: Group,
      coldNumbers: ColdNumbers,
      hotNumbers: HotNumbers,
      percentages: Statistics.reduce((acc, x) => {
        const { number, percent, } = mapPropNames(humps.camelize, x);
        acc[number] = { number, percent, };
        return acc;
      }, {}),
    }),
    bet.apply(
      playerBetOfThisRound.reduce(
        (acc, { index, betValue: value, }) => {
          acc.current[index] = { value, index: +index, valid: true, };
          acc.totalBet += value;
          return acc;
        },
        { totalBet: 0, current: {}, }
      )
    ),
    progressBar.reset(),
    // reset history to prevent sending bets to server again
    // because NO_MORE_BETS message comes after this actions
    history.reset()
  );
};
