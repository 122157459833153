import { handleActions, } from 'redux-actions';

import statisticsActions from '../actions/statistics';

const { statistics, } = statisticsActions;

const INITIAL_STATE = {
  group: {},
  percentages: [],
  gameResults: [],
  coldNumbers: [],
  hotNumbers: [],
};

export default handleActions(
  {
    [statistics.set]: (state, { payload, }) => ({
      ...state,
      ...payload,
      gameResults: [ ...state.gameResults, ...payload.gameResults || [], ],
    }),
  },
  INITIAL_STATE
);
