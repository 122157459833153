import { flatMap, pluck, filter, } from 'rxjs/operators';
import { ofType, combineEpics, } from 'redux-observable';

import { INITIAL_DATA, PLACE_YOUR_BETS, GAME_RESULT, } from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';

import handleInitialData from './handleInitialData';
import handlePlaceYourBets from './handlePlaceYourBets';
import handleGameResult from './handleGameResult';

const {
  socketActions: { socket, },
} = actions;

function initialDataEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap((socketMessage) => handleInitialData(socketMessage, state$.value))
  );
}

function placeYourBetsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === PLACE_YOUR_BETS),
    flatMap((socketMessage) => handlePlaceYourBets(socketMessage))
  );
}

function gameResultEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap((socketMessage) => handleGameResult(socketMessage, state$.value))
  );
}

export default combineEpics(initialDataEpic, gameResultEpic, placeYourBetsEpic);
