import { of, } from 'rxjs';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';


const {
  uiActions: { progressBar, },
} = bootstrapActions;

export default function handlePlaceYourBets() {
  return of(
    progressBar.reset(),
  );
}
