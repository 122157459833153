import { of, concat, } from 'rxjs';
import { last, } from 'ramda';
import { camelize, } from 'humps';

import { mapPropNames, } from '@ezugi/utils';
import { actions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';
import statisticsActions from '../../actions/statistics';

const {
  roundActions: { round, },
  gameActions: { game, },
  uiActions: { progressBar, },
} = actions;
const { statistics, } = statisticsActions;

export default function handleGameResult(socketMessage) {
  const {
    roundId,
    timeStamp,
    WinAmount,
    GameResults,
    LastWinners,
    HotNumbers,
    ColdNumbers,
    Group,
    Statistics,
  } = socketMessage;

  return concat(
    of(
      game.set({
        gameResults: GameResults,
        hotNumbers: HotNumbers,
        coldNumbers: ColdNumbers,
        lastWin: WinAmount,
      })
    ),
    of(
      round.set({
        roundStatus: GAME_RESULT,
        roundId,
        timestamp: timeStamp,
        winnerList: LastWinners,
        winningNumber: last(GameResults),
        winAmount: WinAmount,
      }),
      statistics.set({
        gameResults: GameResults,
        group: Group,
        coldNumbers: ColdNumbers,
        hotNumbers: HotNumbers,
        percentages: Statistics.reduce((acc, x) => {
          const { number, percent, } = mapPropNames(camelize, x);
          acc[number] = { number, percent, };
          return acc;
        }, {}),
      }),
      progressBar.reset(),
    )
  );
}
